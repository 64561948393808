$family: unquote("Poppins:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=#{$family}");

$bgBlue: #003f97;
$bg-primary: #ffffff;
$bg-secondary: #fdfdfd;
$bg-alt: #f8f8f8;
$border-color: #efefef;
$text-primary: #7d7d7d;
$text-secondary: #949494;
$text-dark: #1d1b1a;

$font-family-base: "Poppins", sans-serif;

$border-radius: 5px;
$shadows: 10px 8px 15px 0 rgba(168, 179, 211, 0.24);
