@import "variables", "modularscale";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/grid/all.sass";
@import "bulma/sass/components/card.sass";
@import "bulma/sass/elements/_all.sass";
@import "bulma/sass/form/all.sass";
@import "bulma/sass/helpers/all.sass";

.bgBtn {
  background-color: $bgBlue !important;
  color: #fff !important;
  &:focus {
    background-color: $bgBlue !important;
    color: #fff !important;
  }
  &:hover {
    background-color: $bgBlue !important;
    color: #fff !important;
  }
}
