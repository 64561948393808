@import "variables", "modularscale";

@mixin flex-center($align-items: center, $justify-content: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

.player {
  width: 100%;
  display: flex;
  padding: 20px 35px;
  box-shadow: 10px -6px 15px 0 rgba(168, 179, 211, 0.24);
  min-height: 100px;
  background: $bg-primary;
  @media (max-width: 900px) {
    box-shadow: none;
  }
  @media (max-width: 450px) {
    padding: 20px 10px;
  }
  &__wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
  }
  &__body {
    flex-grow: 1;
    @include flex-center($justify-content: space-between);
  }
  &__control-panel {
    width: 73%;
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
      width: auto;
    }
  }
  &__song {
    &-info {
      flex-grow: 1;
      padding-left: 20px;
      &-wrapper {
        @include flex-center($justify-content: space-between);
        width: 19%;
        margin-right: 45px;
        @media (max-width: 1130px) {
          display: none;
        }
      }
    }
  }
  &__additional-buttons {
    @include flex-center($justify-content: space-between);
    width: 18%;
    @media (max-width: 900px) {
      width: 160px;
    }
    @media (max-width: 450px) {
      width: 130px;
    }
  }
  &__repeat-button {
    svg {
      vertical-align: middle;
      stroke: $text-primary;
    }
    &_active svg {
      stroke: $text-dark;
    }
  }
  &__control-button {
    vertical-align: middle;
    &:first-child {
      margin-right: 20px;
    }
    &:last-child {
      margin-left: 20px;
      margin-right: 40px;
    }
    &:nth-child(2n) {
      svg {
        width: 50px;
        height: 50px;
        @media (max-width: 450px) {
          width: 43px;
          height: 43px;
        }
        .cls-1 {
          stroke: #e4e4e4;
        }
      }
    }
    svg {
      width: 15px;
      height: 15px;
      display: flex;
      fill: $text-primary;
    }
    @media (max-width: 900px) {
      margin: 0;
    }
  }
  &__progress {
    width: 73%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 900px) {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
    }
    &-time {
      color: $text-primary;
      font-size: ms(-1);
      width: 30px;
      font-weight: 500;
      @media (max-width: 900px) {
        display: none;
      }
    }
    &-thumb {
      top: -3px;
      @media (max-width: 900px) {
        display: none;
      }
    }
    &-bar {
      width: 82%;
      height: 4px;
      @media (max-width: 900px) {
        width: 100%;
        height: 9px;
      }
      &-wrapper {
        height: 4px;
        width: 0;
        @media (max-width: 900px) {
          height: 9px;
        }
      }
    }
  }
  &__volume {
    &-control {
      position: relative;
      svg {
        cursor: pointer;
        vertical-align: middle;
        width: 27px;
        height: 27px;
        stroke: $text-primary;
      }
    }
  }
  .volume-slider {
    width: 4px;
    height: 130px;
    &__wrapper {
      bottom: 0;
      position: absolute;
      width: 100%;
    }
    &__thumb {
      right: -3px;
    }
  }
  &__tooltip {
    position: absolute;
    background-color: $bg-primary;
    border-radius: 5px;
    padding: 12px 20px;
    display: none;
    box-shadow: 0 6px 25px 1px rgba(0, 0, 0, 0.2);
    bottom: 40px;
    right: 0;
    &_active {
      display: block;
    }
    &:after {
      content: "";
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $bg-primary;
      bottom: -8px;
      left: 35%;
    }
  }
  .like-btn {
    position: relative;
    svg {
      vertical-align: middle;
      fill: $bg-primary;
      stroke: $text-primary;
      &.like-btn_active {
        stroke: $text-primary;
        fill: $text-primary;
      }
    }
  }
}
